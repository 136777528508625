
import Alert from "@/components/Alert.vue";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
    components: {
        Alert: Alert
    }
})
export default class CustomerNotActivated extends Vue {
}
